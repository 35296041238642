.App {
  text-align: center;
}
.montserrat {
  font-family: montserrat, sans-serif !important;
}

.poppins {
  font-family: poppins-semibold, poppins, sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.Logo{
  width: 130px;
}
.logo-size{
  width: 150px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media (min-width: 1550px) {
  .mt_100{
    margin-top: 100px !important;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



body {
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}
.login-container {
  height: 700px;
  padding: 20px;
  background-color: #F6F5F7;
  border-radius: 5px;
}
.login-container-right {
  
  height: 700px;
  padding: 20px;
  background-color: #ff914d;
  border-radius: 5px;
}
.login-container h2 {
  text-align: center;
}
.login-container input[type="email"],
.login-container input[type="password"],.login-container input[type="text"] {
  width: 100%;
  height: 55px;
  margin: 10px;
  padding: 10px;
  border: 1px solid #E2E1E5 !important;
  background-color: #ffffff !important;
  border-radius: 5px;
}
.admin input[type="email"],
.admin input[type="password"],.admin input[type="text"] {
  width: 50% !important;
  display: inline-flex !important;
}


.login-container input[type="submit"] {
  width: 35%;
  height: 55px;
  margin: 10px;
  padding: 10px;
  background-color: #ff914d;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.login{
  width: 35%;
  height: 55px;
  margin: 10px;
  padding: 10px;
  background-color: #ff914d;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.login-container input[type="submit"]:hover {
  background-color: #d17f4f;
}

.fm {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.mt{
  margin-top: 100px;
  margin-bottom: 5px;
  margin-left: 30px;
}
.mb{
  margin-bottom: 20px !important;
  margin-left: 35px;
}
.passw{
  /* display: flex; */
  position: relative;
}
.login-label{
  display: flex;
  margin-left: 20px;
}
.link-primary{
  margin-top: 25px;
  margin-bottom: 20px;
}
.btn:disabled{
  background-color: #333;
}
#eye {
  position: absolute;
  top: 73%;
  right: 20px;
  width: 15px;
  transform: translateY(-50%);
  cursor: pointer;
}

.signup{
  width: 35%;
  height: 55px;
  margin: 10px;
  padding: 15px;
  background-color: #ffffff;
  color: #ff914d;
  border: none;
  border-radius: 50px;
  cursor: pointer;
}
.signup:hover{
  background-color: #ffffff;
  box-shadow: 4px 3px 9px 6px #96969633;
}
.login:hover{
  box-shadow: 4px 3px 9px 6px #96969633;
}
.row1{
  box-shadow: 4px 3px 9px 6px #96969633;
  

}

.clmp{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.btn-bc{
  border: 1px solid #ff914d;
  color: #ff914d;
}
.btn-bc:hover{
  color: #fff;
  background-color: #ff914d;
}

.adminimg{
  width: 50% !important;
  padding-bottom: 30px ;
}

.containe {
  width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  text-align: center;
}



h1 {
  font-size: 2em;
  margin-top: 0;
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="email"], input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

input[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: #ff914d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #ff914d;
}

a {
  color: #4CAF50;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}

a:hover {
  text-decoration: underline;
}


.mtt{
  margin-top: 200px;
}



.text-size{
  font-size: 26px;
  font-weight: 200 !important;
  padding-left: 5px;
}


.bg-blu{
  background-color: #E9EEFF;
}

.bg-bc{
  background-color: #ff914d;
}

.pd-top{
  padding-top: 60px;
  padding-bottom: 60px;

}



.ld-img-ruby{
  display: ruby;
  
}
.p24{
  font-size: 24px;
}
.brd{
  border-radius: 20px !important;
}

.img-size{
  width: 100px;
}


#bc{
  background-color: #39364F;
}

.pd-td{
  padding-top: 80px;
  padding-bottom: 80px;
}

.faq-text{
  font-size: 25px;
}



.line-color {
  border: 1px solid #999999 !important; 
}


.align{
  display: flex;
  justify-content: space-between;
}


.headertxt {
  padding-bottom: 25px;
  padding-top: 25px;
}


.headertxt,.headerttext, .txtsize, .text-blue{
  color: #1f204e;
}

.navbar-links.active {
  display: flex;
}

.navbar-toggle {
  display: block;
}

.sdw{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;}


  /* .sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ff914d;
    overflow-x: hidden;
  }
  
  .sidebar.open {
    left: 0;
  }
  
  .profile {
    padding: 20px;
    text-align: center;
    border-bottom: 1px solid #fff6f6;
  }
  
  .profile-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .profile-name {
    color: #fff;
  }
  
  .sidebar-menu {
    list-style-type: none;
    padding: 0;
    margin-left: auto;
  }
  
  .sidebar-menu li {
    padding: 10px;
    padding-left: 35px;
  }
  
  #dashicon{
    padding-right: 15px;
  }

  .sidebar-menu li a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
  }
  
  .sidebar-menu li a:hover {
    color: #111010; 
  }
  
  .sidebar-hover:hover{
    background-color: #ffffff !important;
    color: #ff914d !important;
  }
  .sidebar-hover:active{
    background-color: #ffffff !important;
    color: #ff914d !important;
  } */
  
/* Sidebar.css */

.sidebar {
  width: 250px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #ff914d;
  overflow-x: hidden;
  transition: width 0.3s;
  z-index: 1000; /* Ensure the sidebar is on top */
}

.sidebar.collapsed {
  width: 90px;
}

.profile {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #fff6f6;
}

.profile-photo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
  transition: width 0.3s, height 0.3s;
}

.profile.collapsed .profile-photo {
  width: 40px;
  height: 40px;
}

.profile-name {
  color: #fff;
  transition: opacity 0.3s;
}

.profile.collapsed .profile-name {
  opacity: 0;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin-left: auto;
}

.sidebar-menu li {
  padding: 10px;
  padding-left: 35px;
  display: flex;
  align-items: center;
  transition: padding-left 0.3s;
}

.sidebar-menu li.collapsed {
  padding-left: 10px;
}

#dashicon {
  padding-right: 15px;
  transition: padding-right 0.3s;
}

#dashicon.collapsed {
  padding-right: 0px;
}

.sidebar-menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  transition: opacity 0.3s;
}

.sidebar-menu li a.collapsed {
  opacity: 0;
}

.sidebar-menu li a:hover {
  color: #111010; /* Change this to your desired hover color */
}

.sidebar-hover:hover {
  background-color: #ffffff !important;
  color: #ff914d !important;
}

.sidebar-hover:active {
  background-color: #ffffff !important;
  color: #ff914d !important;
}

.container-with-sidebar {
  margin-left: 250px;
  transition: margin-left 0.3s;
}

.container-with-collapsed-sidebar {
  margin-left:  25px;
  transition: margin-left 0.3s;
}

.navbar-toggler {
 
  right: 0;
  top: 20px;
  background-color: #ff914d;
  border: none;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  z-index: 1001; /* Ensure the button is above other elements */
  transition: right 0.3s;
}

.sidebar.collapsed .navbar-toggler {
  right: -5px; /* Adjust this value if necessary */
}

.white{
  color: #fff;
}
/* Responsive styles */
@media (max-width: 768px) {
  .mt{
    margin-top: 0px;
  }
  .header {
    flex-direction: column;
    align-items: center;
  }

  .text-sub{
    font-size: 15px !important;
  }

  .search-bar-candi, .filter-candi {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .table {
    overflow-x: auto;
    width: 100%;
  }

  .table th, .table td {
    padding: 8px;
    font-size: 14px;
  }
}




.col-md-3-card{
  width: 480px;
}

.pd-20{
  padding: 20px;
}
.container-with-sidebar {
  margin-left: 195px; /* Adjust this value according to your sidebar width */
}
  .service-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .service-title {
    margin-top: 0;
  }
  
  .service-description {
    margin-bottom: 10px;
  }
  
  .service-price {
    font-weight: bold;
  }
.container-main{
  margin-left: 50px;
  padding: 20px;
  flex-grow: 1 !important;
}  

.my-left{
  margin-top: 20px;
  margin-left: 20px;
}

.search-bar {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.searchbar{
  width: 70%;
  padding: 30px;
}

input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  /* border-radius: 30px; */
  background-color: #e6e6e6;
}

button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #ff914d;
  color: #fff;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

.border-color{
  border-color: #ff914d;
  padding: 7px;
  font-weight: 100 !important;
  color: #ff914d;

}


.profile-right{
  margin-left: 25px;
  display: block;
  width: 25%;
  padding: 20px;
  text-align: center;
}

.profile-right-name{
  color: #ff914d;
}

.filter-options{
  margin-right: 60px;
  display: -webkit-box;
  width: 200px;
  margin-top: 20px;
  padding: 10px;
  border-color: #0056b3;
  margin-left: auto;
  color: #0056b3;
  font-weight: 600;
}
.filter-color{
  border-color: #0056b3;
  color: #0056b3;
}
.text-job
{
  color: #000;
}
.text-label{
  color: #999999;
}
.mt-20{
  margin-top: 30px;
}

.labels{
  display: ruby-text;
  margin-top: 20px;
  text-align: center;

}

.label-border{
  margin-left: 25px;
  border: 1px solid #000;
  border-radius: 25px;
  padding: 8px;
}

.line{
  background-color: #000;
}

.bg-orange{
  background-color: #ff914d;
  color: #ffffff;
  border: 1px solid #ff914d !important;
}
.bg-white{
  background-color: #ffffff !important;
  color: #000000 !important;

}

.hoverrr:hover{
 background-color: #ffffff  !important;
 color: #ff914d    !important;
}

.active-button{
  background-color: #ffffff;
  color: #ff914d;
}

.hoverrr{
  width: 180px;
}

.header{
  display: flex;
}
.justify{
  justify-content: space-between;
}

.search-bar-candi{
  margin-bottom: 12px;
  border-radius: 30px;
}

.filter-candi{
  margin-right: 60px;
  margin-bottom: 20px;
  color: #5B9DFF;
  border-color: #5B9DFF;
  
}

.filter-job{
  margin-right: 60px;
  display: -webkit-box;
  width: 184px;
  margin-top: 20px;
  padding: 10px;
  margin-left: auto;
}


/* Modal container */
.modal-container {
  margin-top: 50px;
  width: 90%;
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 50px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure modal is on top of other elements */
}

/* Modal overlay */
.modal-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  z-index: 999; /* Ensure overlay is behind the modal */
}

/* Close button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #333;
}

/* Modal content */
.modal-content {
  border-radius: 20px;
  margin-top: 10px;
  padding: 20px;

}
.modal-containerw {
  
  position: fixed;
  top: 35px;
  left: 100px;
  width: 90%;
  height: 90%;
  overflow: scroll;
  display: flex;

  background-color: #fff;
  z-index: 1000;
}

.details{
  display: flex;
    margin-top: 20px;
    text-align: justify;
    justify-content: space-around;

}

.candi-label{
  padding: 20px;
  color: #6E7781;
}
.candi-text{
  color: #000000;
}

.job-dec{
  padding: 65px;
 
}

.width-100{
  width: 101%;
}

.job-dec-title{
  padding-top: 30px;
  font-size: 36px;
}
.job-dec-text{
  font-size: 24px;
  padding-bottom: 15px;
}

.pd-30{
  padding-bottom: 30px;
}

.my-row{
  justify-content: space-evenly;
}
.my-card{
  flex: auto;
}
.margin-left{
  margin-left: 4px;
  margin-top: 7px;
}

.margin-bottom{
  margin-bottom: 40px;
}
.dashboard-content{
  margin-top: 10px;
  padding: 50px;
  width: 65%;
}
.text-dash{
  font-size: 30px;
}
.item{
  display: flex;
}
.mrg-left{
  margin-left: 20px;
}
.dashdoard-notification{
  width: 25%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: clip;

}
.text-centerr{
  display: flex;
  justify-content: space-between;
}
.fsize{
  width: 95px;
  height: 40px;
}
.img-size{
  padding: 10px;
}
.new-app{
  display: flex;
  justify-content: center;
  align-items: center;
}

.aln-txt{
  text-align: start;
}
.search-bar{
  border-radius: 30px;
  width: 100%;
}

.searchbarr{
  padding: 20px;
  width: 75%;
}

.filter-job-candi {
  margin-right: 60px;
  display: -webkit-box;
  width: 184px;
  margin-top: 20px;
  padding: 10px;
  margin-left: auto;
  height: 40px;
}

.filter-candi-candi {
  margin-right: 60px;
  margin-bottom: 20px;
  color: #5B9DFF;
  border-color: #5B9DFF;
}

.forgot{
  width: 300px;
}

.forgot_container{
  display: flow-root;
  text-align: -webkit-center;
}
.logo{
  width: 150px;
}
.btnmy{
  margin-left: 0px;
}
.btnbr{
  border: 2px solid orange; /* Add an orange border */
}

.important-font-container {
  font-family: poppins-semibold, poppins, sans-serif !important;
}

